/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: '/dashboard',
    name: 'Dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard',
  },
  // {
  //   url: '/employee/all',
  //   name: 'Employees',
  //   icon: 'UserIcon',
  //   i18n: 'Employees',
  // },
  // {
  //   url: '/story/all',
  //   name: 'Story',
  //   icon: 'LayoutIcon',
  //   i18n: 'Story',
  // },
  // {
  //   url: '/benefit/all',
  //   name: 'Benefits',
  //   icon: 'PieChartIcon',
  //   i18n: 'Benefits',
  // },
  // {
  //   url: '/event/all',
  //   name: 'Events',
  //   icon: 'CalendarIcon',
  //   i18n: 'Events',
  // },
  // {
  //   url: '/chatroom/all',
  //   name: 'Tables',
  //   icon: 'MessageSquareIcon',
  //   i18n: 'Tables',
  // },
  // {
  //   url: '/offers/all',
  //   name: 'Offers',
  //   icon: 'DropletIcon',
  //   i18n: 'Offers',
  // },
    //Main menu bar ends here...
]

